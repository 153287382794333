<template>
  <section id="pricing-plan">
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col
            v-for="item in dataList"
            :key="item.id"
            md="4"
          >
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                height="150px"
                width="150px"
                :src="getlogo(item.id)"
                alt="logo do agente"
              />
              <!--/ img -->
              <h3>{{ item.nome }}</h3>
              <b-card-text>
                {{ item.perfis.toString() }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <b-badge>
                    {{ item.descricao }}
                  </b-badge>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- buttons -->
              <b-button
                v-if="item.possuiAssinatura == true"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                @click.stop="editData(item.id)"
              >
                <feather-icon
                  icon="SettingsIcon"
                  class="mr-50"
                />
                {{ $t('spot.meuAgente.configurarBtn') }}
              </b-button>

              <b-button
                v-if="item.possuiAssinatura == false"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-modal.modal-assinar-agente
                block
                class="mt-2"
                variant="success"
                @click.stop="modalSeguirAgente = item"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                {{ $t('spot.meuAgente.SeguirBtn') }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      v-if="modalSeguirAgente"
      id="modal-assinar-agente"
      ref="modal-assinar-agente"
      cancel-variant="outline-secondary"
      :ok-title="$t('spot.meuAgente.SeguirBtn')"
      :cancel-title="$t('futures.agente.assinante.sair')"
      :title="$t('spot.meuAgente.seguirAgente')"
      hide-header-close
      centered
      no-close-on-backdrop
      @ok="assinarAgente(modalSeguirAgente.id)"
    >
      <h2>{{ $t('spot.meuAgente.SeguirTextoConfirmacao') }} <strong>{{ modalSeguirAgente.nome }}</strong> ?</h2>
    </b-modal>

  </section>
</template>

<script>

import {
  BCard, BRow, BCol, BButton, VBTooltip, BImg, BCardText, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import meuAgenteStoreModule from './meuAgenteStoreModule'

const MEU_AGENTE_FUT_STORE_MODULE_NAME = 'meu-agente-fut'

export default {

  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    BCardText,
    BBadge,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      dataList: [],
      modalSeguirAgente: null,
    }
  },

  created() {
    if (!store.hasModule(MEU_AGENTE_FUT_STORE_MODULE_NAME)) store.registerModule(MEU_AGENTE_FUT_STORE_MODULE_NAME, meuAgenteStoreModule)
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(MEU_AGENTE_FUT_STORE_MODULE_NAME)) store.unregisterModule(MEU_AGENTE_FUT_STORE_MODULE_NAME)
  },

  methods: {

    getStatusColor(value) {
      let cortemp = ''
      if (value === 'Ativo') {
        cortemp = 'success'
      } else if (value === 'Inativo') {
        cortemp = 'danger'
      } else if (value === 'Expirado') {
        cortemp = 'danger'
      }
      return cortemp
    },

    assinarAgente(id) {
      store
        .dispatch('meu-agente-fut/assinarAgente', id)
        .then(() => {
          this.editData(id)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao assinar.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    editData(id) {
      this.$router.push(`/futures/meu-agente/${id}`).catch(() => {})
    },

    getlogo(id) {
      return `${process.env.VUE_APP_STATIC}agente/${id}/logo.png`
    },

    fetchList() {
      store
        .dispatch('meu-agente-fut/getMeuAgente')
        .then(response => {
          this.dataList = response.data
          if (this.dataList.length === 1 && this.dataList[0].possuiAssinatura === true) {
            this.$router.push({ name: 'fut-meu-agente-edit', params: { id: this.dataList[0].id } })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

}
</script>
